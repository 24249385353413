<template>
	<div id="blog" class="container">

		<div class="col-md-12 section_bg_gray">
			<div class="container-fluid">
				<div class="row">
					<div class="col-md-12">
						<div class="ptb-50">
							<h2 class="text-center">Nos services de paie vous aideront pour :</h2>
						</div>
					</div>
				</div>
				<div class="row">
	
					<div class="col-md-6 col-lg-4 d-flex align-items-stretch mb-3 mt-3">
						<div class="single_service">
							<div class="service_figure">
								<img src="https://img.icons8.com/officel/1x/yoga.png" class="img-fluid">
							</div>
							<h4 class="service_title">Harmoniser votre expérience</h4>
						</div>
					</div>
					
					<div class="col-md-6 col-lg-4 d-flex align-items-stretch mb-3 mt-3">
						<div class="single_service">
							<div class="service_figure">
								<img src="https://img.icons8.com/flat_round/2x/loop.png" class="img-fluid">
							</div>
							<h4 class="service_title">Améliorer les performances</h4>
						</div>
					</div>
					
					<div class="col-md-6 col-lg-4 d-flex align-items-stretch mb-3 mt-3">
						<div class="single_service">
							<div class="service_figure">
								<img src="https://img.icons8.com/officel/1x/low-risk.png" class="img-fluid">
							</div>
							<h4 class="service_title">Limiter les risques</h4>
						</div>
					</div>
					
				</div>
			</div>
		</div>

		<div class="col-md-12 section_bg_gray">
			<div class="container-fluid">
				<div class="row">
					<div class="col-md-12">
						<div class="ptb-50">
							<h2 class="text-center">Données clés FreeBoss</h2>
						</div>
					</div>
				</div>
				<div class="row">
	
					<div class="col-md-6 col-lg-4 d-flex align-items-stretch mb-3 mt-3">
						<div class="single_service">
							<div class="service_figure">
								<img src="https://img.icons8.com/plasticine/1x/invoice-1.png" class="img img-fluid">
							</div>
							<h4 class="service_title">1.4 M</h4>
							<h4 class="service_content">Bulletn de paie traité</h4>
						</div>
					</div>
					
					<div class="col-md-6 col-lg-4 d-flex align-items-stretch mb-3 mt-3">
						<div class="single_service">
							<div class="service_figure">
								<img src="https://img.icons8.com/plasticine/1x/gender-neutral-user.png" class="img img-fluid">
							</div>
							<h4 class="service_title">158 000</h4>
							<h4 class="service_content">Utilisateurs</h4>
						</div>
					</div>
					
					<div class="col-md-6 col-lg-4 d-flex align-items-stretch mb-3 mt-3">
						<div class="single_service">
							<div class="service_figure">
								<img src="https://img.icons8.com/color/2x/collaborator-male.png" class="img img-fluid">
							</div>
							<h4 class="service_title">145</h4>
							<h4 class="service_content">Clients</h4>
						</div>
					</div>
					
				</div>
			</div>
		</div>

		<div class="col-md-12 section_bg_custom">
			<div class="container-fluid">
				<div class="row">
					<div class="col-md-12">
						<div class="ptb-50">
							<h2 class="text-center">Vos avantages avec FreeBoss</h2>
						</div>
					</div>
				</div>
				<div class="row">
	
					<div class="col-md-6 col-lg-4 d-flex align-items-stretch mb-3 mt-3">
						<div class="single_service">
							<h4 class="service_title">Efficatité</h4>
							<div class="service_content">
								<ul>
									<li>Une Solution full web, simple et ergonomique</li>
									<li>Un pack de module optionnel</li>
									<li>Une utilisation simple et optimale</li>
								</ul>
							</div>
						</div>
					</div>
					
					<div class="col-md-6 col-lg-4 d-flex align-items-stretch mb-3 mt-3">
						<div class="single_service">
							<h4 class="service_title">Fiabilité</h4>
							<div class="service_content">
								<ul>
									<li>Une Solution full web, simple et ergonomique</li>
									<li>Un pack de module optionnel</li>
									<li>Une utilisation simple et optimale</li>
								</ul>
							</div>
						</div>
					</div>
					
					<div class="col-md-6 col-lg-4 d-flex align-items-stretch mb-3 mt-3">
						<div class="single_service">
							<h4 class="service_title">Productivité</h4>
							<div class="service_content">
								<ul>
									<li>Une Solution full web, simple et ergonomique</li>
									<li>Un pack de module optionnel</li>
									<li>Une utilisation simple et optimale</li>
								</ul>
							</div>
						</div>
					</div>
					
				</div>
			</div>
		</div>

	</div>
</template>

<script>

export default {
	name: 'Home',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	#blog .container-carousel{
		color: #fafafa;
		height: 100%;
		padding-top: 160px;
		background-image: url("https://cdn.pixabay.com/photo/2017/03/13/17/26/ecommerce-2140604__480.jpg");
		background-repeat: no-repeat;
		background-size: cover;
	}
	.jumbotron{
		background: none !important;
	}
	#blog .jumbotron .jumbotron-title{
		font-weight: bold;
		font-size: 24pt;
		text-align: center;
		width: 50%;
		height: 50%;
		color: #fafafa;
		background-color: #dc393a;
		padding: 20px;
	}
	.container-carousel .row [class*='col-md-']{
		padding-left:0;
		padding-right:0;
	}

	.ptb-50{
		padding: 50px 50px 10px 50px;
	}

	.ptb-50 h2{
		color: #dc393a;
		font-weight: bold;
		font-size: 25pt;
	}

	.section_bg_gray,.section_bg_custom {
		background-color: bold;
		padding-bottom: 50px;
	}

	.section_bg_gray .single_service {
		background-color: #fafafa;
		text-align: center;
		width: 90%;
		padding: 30px 30px;
		border-radius: 10px;
		position: relative;
		box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2);
	}

	.section_bg_gray .single_service .service_figure img {
		margin: 0 auto;
	}
	.section_bg_gray .single_service .service_title {
		font-weight: 600;
		font-size: 19px;
		padding-top: 15px;
		padding-bottom: 25px;
		color: #555555;
		margin: 0;
	}

	.section_bg_custom h2{
		color: #dc393a;
	}
	.section_bg_custom .single_service{
		background-color: #fafafa;
		width: 90%;
		padding: 25px 25px;
		border-radius: 10px;
		position: relative;
		box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2);
	}
	.section_bg_custom .service_title{
		color: #dc393a;
		font-weight: bold;
	}
	.section_bg_custom .service_content{
		font-weight: bold;
		color: #444444;
		text-align: justify;
	}

</style>
